import React from 'react';
import { Space } from 'antd';
import {
  PlusOutlined,
  RightCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import RoundContainer from 'common/components/UI/RoundContainer/RoundContainer';
import { getGreyColor } from 'common/lib/utils/helpers';
import { UniversalCard } from 'common/types/cards';
import { Card as CardComponent, GreyContainer } from 'common/components/UI';
import { useIntl } from 'react-intl';

import classes from './Cards.module.css';
import { CardType } from 'river/types';
import { useSelector } from 'react-redux';
import { getIsUserEU } from 'common/store/selectors/app';

type CardsBlockProps = {
  cards: UniversalCard[];
  color: string;
  isAddCardAvailable: boolean;
  onAddCard?: () => unknown;
  onCard?: (id: number | string) => unknown;
  accountSoftBlocked?: boolean;
  selectedType?: CardType;
  setSelectedType?: (v: CardType) => void;
};

const CardsBlock: React.FC<CardsBlockProps> = ({
  cards = [],
  color,
  isAddCardAvailable,
  onCard,
  onAddCard,
  accountSoftBlocked,
  selectedType,
  setSelectedType,
}) => {
  const { formatMessage: t } = useIntl();
  const isUserEU = useSelector(getIsUserEU);

  const onHandleSelectCard = (type: CardType) => {
    setSelectedType && setSelectedType(type);
    onAddCard && onAddCard();
  };

  return (
    <GreyContainer title={t({ id: 'sections.card.card.title' })}>
      <Space size={8}>
        {cards.map((card) => (
          <CardComponent
            id="river-card-img"
            key={card.id}
            size="m"
            bgColor={color}
            status={card.universalCardStatus}
            shortNumber={card.number?.slice(-4) || ''}
            onClick={() => {
              if (onCard && card.id) onCard(String(card.id));
            }}
          />
        ))}

        {/* {isAddCardAvailable && !accountSoftBlocked ? (
          <RoundContainer
            bgColor={getGreyColor(0.08)}
            padding={10}
            pointer
            onClick={onAddCard}>
            <PlusOutlined style={{ fontSize: 18 }} />
          </RoundContainer>
         ) : null} */}
        {isAddCardAvailable && !accountSoftBlocked ? (
          <>
            <div
              className={classes['add-card']}
              onClick={() => onHandleSelectCard('plastic')}>
              <CardComponent
                title="PLASTIC"
                id="river-card-img1"
                size="m"
                bgColor={color}
                shortNumber={'****'}
              />
              <div className={classes['add-card_footer']}>
                <p>{t({ id: 'river.plasticCard' })}</p>
                {isUserEU ? <RightCircleFilled /> : <ExclamationCircleFilled />}
              </div>
            </div>

            <div
              className={classes['add-card']}
              onClick={() => onHandleSelectCard('virtual')}>
              <CardComponent
                title="VIRTUAL"
                id="river-card-img1"
                size="m"
                bgColor={color}
                shortNumber={'****'}
              />
              <div className={classes['add-card_footer']}>
                <p>{t({ id: 'river.virtualCard' })}</p>
                <RightCircleFilled />
              </div>
            </div>
          </>
        ) : null}
      </Space>
    </GreyContainer>
  );
};

export default CardsBlock;
