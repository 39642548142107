/* eslint no-undef: 0 */
import userApi from "../../api/user";

const initWidget = (user) => {
  const restoreId = user.restoreId;
  window.fcWidget.init({
    token: '06a86887-750f-4254-b9c5-7a629f8fe357',
    host: 'https://weststeincard.freshchat.com',
    widgetId: 'cec83f82-25d5-4562-8fe1-4cdef479cb93',
    externalId: user.id,
    restoreId: restoreId ? restoreId : null,
  });
  // eslint-disable-next-line no-debugger
  // debugger
};

const createUser = (user) => {
  window.fcWidget.user.get(function(resp) {
    const status = resp && resp.status,
      data = resp && resp.data;

    if (status !== 200) {
      console.log("Updating user properties");
      window.fcWidget.user.setProperties({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        phoneCountryCode: user.phoneCountryCode,
        loginMethod: "WEB"
      });

      window.fcWidget.on("user:created", function(resp) {
        const status = resp && resp.status,
          data = resp && resp.data;
        if (status === 200) {
          if (data.restoreId) {
            console.log("Update restore ID for User:" + data.restoreId);
            // Update restoreId in your database
            userApi.settings.setRestoreId(data.restoreId).then(response => {
              console.log("RestoreId updated.");
              console.log(response)
            }).catch(error => {
              console.log("Something happened while updating restoreId");
              console.log(error);
            })
          }
        }
      });
    } else {
      // if user doesn't have a restoreId and we have it in request response
      if (!user.restoreId && resp.data.restoreId) {
        console.log("Updating restoreId for existing user.");
        userApi.settings.setRestoreId(resp.data.restoreId).then(response => {
          console.log("RestoreId updated.");
          console.log(response);
        }).catch(error => {
          console.log("Something happened while updating restoreId");
          console.log(error);
        })
      }
    }
  });
};

export const initChat = (user) => {
  initWidget(user);
  const userData = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    phoneCountryCode: user.phoneCountry
  };
  createUser(userData);
}

export const destroyChat = () => {
  window.fcWidget.destroy();
}