import { ENDPOINTS } from '../lib/constants/values';
import { API } from 'common/http';
import JSONBigInt from 'json-bigint';
import {
  ApplyForPCCardBody,
  BlockCardResponse,
  CreateVirtualCardResponse,
  GetCardNumberAndCVNResponse,
  GetCardRulesResponse,
  SendGetPINResponse,
  SetCardRulesBody,
  SignActivateCardBody,
  SignEnrollCardBody,
  SignEnrollCardResponse,
  SignGetPINResponse,
  UnenrollCardResponse,
  getApplicationsResponse,
} from './dto';
import { SignParams } from './dto/transfers';
import { BlockReason } from 'river/types';
import { ApiResponse } from 'common/types/app';
import { AxiosRequestConfig } from 'axios';

type CardApi = {
  getPCCard: () => Promise<any>;
  activatePCCard: (accountId: number | string, cardId: number | string) => Promise<any>;
  activatePCCardSign: (
    accountId: number | string,
    cardId: number | string,
    body: SignActivateCardBody,
  ) => Promise<any>;
  activatePCCardSignAdvanced: (
    accountId: number | string,
    cardId: number | string,
    body: SignActivateCardBody,
    options: AxiosRequestConfig,
  ) => Promise<any>;
  activatePCCardResend: (
    accountId: number | string,
    cardId: number | string,
  ) => Promise<any>;
  applyForPCCard: (accountId: number, body: ApplyForPCCardBody) => Promise<any>;
  replacePCCard: (
    accountId: number | string,
    cardId: number | string,
    cardDeliveryMethodId: number | string,
  ) => Promise<ApiResponse<unknown>>;
  resendVCToken: (accountId: number, cardId: string) => Promise<any>;
  // getApplicationLocations: () => Promise<any>;
  createVCApplication: (accountId: number) => Promise<CreateVirtualCardResponse>;
  replaceVCCard: (
    accountId: number | string,
    cardId: number | string,
  ) => Promise<ApiResponse<unknown>>;
  signVCApplication: (accountId: number, cardId: string, body: any, options?: AxiosRequestConfig) => Promise<any>;
  // getCardInfo: (accountId: number, cardId: string) => Promise<any>;
  blockCard: (
    accountId: number | string,
    cardId: number | string,
  ) => Promise<BlockCardResponse>;
  sendEnrollToken: (accountId: number | string, cardId: number | string) => Promise<any>;
  resendEnrollToken: (
    accountId: number | string,
    cardId: number | string,
  ) => Promise<any>;
  signEnrollCard: (
    accountId: number | string,
    cardId: number | string,
    body: SignEnrollCardBody,
    options?: AxiosRequestConfig,
  ) => Promise<SignEnrollCardResponse>;
  sendGetPinToken: (
    accountId: number | string,
    cardId: number | string,
  ) => Promise<SendGetPINResponse>;
  resendGetPinToken: (
    accountId: number | string,
    cardId: number | string,
  ) => Promise<any>;
  signGetPin: (
    accountId: number | string,
    cardId: number | string,
    options: AxiosRequestConfig & { params: SignParams },
  ) => Promise<SignGetPINResponse>;
  hardBlockCard: (
    accountId: number | string,
    cardId: number | string,
    reason: BlockReason,
  ) => Promise<BlockCardResponse>;
  getCardNumberAndCVN: (
    accountId: number | string,
    cardId: number | string,
  ) => Promise<GetCardNumberAndCVNResponse>;
  getCardRules: (
    accountId: number,
    cardId: string | number,
  ) => Promise<GetCardRulesResponse>;
  setCardRule: (
    accountId: number,
    cardId: string | number,
    body: SetCardRulesBody,
  ) => Promise<any>;
  unblockCard: (
    accountId: number | string,
    cardId: number | string,
  ) => Promise<BlockCardResponse>;
  unenrollCard: (
    accountId: number | string,
    cardId: number | string,
  ) => Promise<UnenrollCardResponse>;
  getCards: (accountId: number) => Promise<any>;
  getApplications: () => Promise<getApplicationsResponse>;
};

const cardApi: CardApi = {
  getPCCard() {
    return API.get(ENDPOINTS.CARD_CTRL.PC_GET_CARD);
  },

  activatePCCard(accountId, cardId) {
    return API.post(ENDPOINTS.CARD_CTRL.PC_ACTIVATE_CARD(accountId, cardId), {}, {
      transformResponse: [(data: string) => JSONBigInt.parse(data)],
    });
  },

  activatePCCardSign(accountId, cardId, body) {
    return API.post(ENDPOINTS.CARD_CTRL.PC_ACTIVATE_CARD_SIGN(accountId, cardId), body);
  },

  activatePCCardSignAdvanced(accountId, applicationId, body, options) {
    return API.post(
      ENDPOINTS.CARD_CTRL.PC_ACTIVATE_CARD_SIGN_ADVANCED(accountId, applicationId),
      body,
      options,
    );
  },

  activatePCCardResend(accountId, cardId) {
    return API.post(ENDPOINTS.CARD_CTRL.PC_ACTIVATE_CARD_RESEND(accountId, cardId));
  },

  applyForPCCard(accountId, body) {
    return API.post(ENDPOINTS.CARD_CTRL.PC_APPLICATION(accountId), body);
  },

  replacePCCard(accountId, cardId, cardDeliveryMethodId) {
    return API.post(
      ENDPOINTS.CARD_CTRL.PC_REPLACE_CARD(accountId, cardId),
      {},
      {
        params: {
          cardDeliveryMethodId,
        },
        transformResponse: [(data: string) => JSONBigInt.parse(data)],
      },
    );
  },

  resendVCToken(accountId, cardId) {
    return API.post(ENDPOINTS.CARD_CTRL.VC_RESEND_TOKEN(accountId, cardId));
  },

  // getApplicationLocations() {
  //   return API.get(ENDPOINTS.CARD_CTRL.GET_APPLICATION_LOCATIONS);
  // },

  createVCApplication(accountId) {
    return API.post(ENDPOINTS.CARD_CTRL.VC_CREATE_APPLICATION(accountId));
  },

  replaceVCCard(accountId, cardId) {
    return API.post(ENDPOINTS.CARD_CTRL.VC_REPLACE_CARD(accountId, cardId));
  },

  signVCApplication(accountId, cardId, body, options) {
    return API.post(ENDPOINTS.CARD_CTRL.VC_SIGN_APPLICATION(accountId, cardId), body, options);
  },

  // getCardInfo(accountId, cardId) {
  //   return API.get(ENDPOINTS.CARD_CTRL.GET_CARD_INFO(accountId, cardId));
  // },

  blockCard(accountId, cardId) {
    return API.post(ENDPOINTS.CARD_CTRL.BLOCK_CARD(accountId, cardId));
  },

  sendEnrollToken(accountId, cardId) {
    return API.post(ENDPOINTS.CARD_CTRL.SEND_ENROLL_TOKEN(accountId, cardId));
  },

  resendEnrollToken(accountId, cardId) {
    return API.post(ENDPOINTS.CARD_CTRL.RESEND_ENROLL_TOKEN(accountId, cardId));
  },

  signEnrollCard(accountId, cardId, body, options) {
    return API.post(
      ENDPOINTS.CARD_CTRL.SIGN_ENROLL_CARD(accountId, cardId),
      body,
      options,
    );
  },

  sendGetPinToken(accountId, cardId) {
    return API.post(ENDPOINTS.CARD_CTRL.SEND_GET_PIN_TOKEN(accountId, cardId));
  },

  resendGetPinToken(accountId, cardId) {
    return API.post(ENDPOINTS.CARD_CTRL.RESEND_GET_PIN_TOKEN(accountId, cardId));
  },

  signGetPin(accountId, cardId, options) {
    return API.post(ENDPOINTS.CARD_CTRL.SIGN_GET_PIN(accountId, cardId), undefined, options);
  },

  hardBlockCard(accountId, cardId, reason) {
    return API.post(ENDPOINTS.CARD_CTRL.HARD_BLOCK_CARD(accountId, cardId, reason));
  },

  getCardNumberAndCVN(accountId, cardId) {
    return API.get(ENDPOINTS.CARD_CTRL.GET_CARD_NUMBER_AND_CVN(accountId, cardId));
  },

  getCardRules(accountId, cardId) {
    return API.get(ENDPOINTS.CARD_CTRL.GET_CARD_RULES(accountId, cardId));
  },

  setCardRule(accountId, cardId, body) {
    return API.post(ENDPOINTS.CARD_CTRL.SET_CARD_RULE(accountId, cardId), body);
  },

  unblockCard(accountId, cardId) {
    return API.post(ENDPOINTS.CARD_CTRL.UNBLOCK_CARD(accountId, cardId));
  },

  unenrollCard(accountId, cardId) {
    return API.post(ENDPOINTS.CARD_CTRL.UNENROLL_CARD(accountId, cardId));
  },

  getCards(accountId) {
    return API.get(ENDPOINTS.CARD_CTRL.GET_CARDS(accountId));
  },

  getApplications() {
    return API.get(ENDPOINTS.CARD_CTRL.GET_APPLICATIONS, {
      transformResponse: [(data: string) => JSONBigInt.parse(data)],
    });
  },
};

export default cardApi;
