import React, { useState } from 'react';
import { Drawer, Segmented } from 'antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { CARD_TYPE } from 'river/lib/constants/values';
import { getIsUserEU } from 'common/store/selectors/app';
import { CardType } from 'river/types';
import VirtualForm from './VirtualForm';
import PlasticForm from './PlasticForm';
import classes from './DrawerAddCard.module.css';

type DrawerAddCardProps = {
  isOpen: boolean;
  onClose: () => unknown;
  selectedType: CardType;
  setSelectedType: (v: CardType) => void
};

const DrawerAddCard: React.FC<DrawerAddCardProps> = ({ isOpen, onClose, selectedType, setSelectedType }) => {
  const { formatMessage: t } = useIntl();
  const isUserEU = useSelector(getIsUserEU);

  return (
    <Drawer
      title={t({ id: 'sections.card.card.buttons.create' })}
      open={isOpen}
      onClose={onClose}
      width={560}
      destroyOnClose>
      <Segmented
        className={classes.segmented}
        block
        options={selectedType === 'plastic' ? [
          { value: CARD_TYPE.PHYSICAL, label: t({ id: 'card.status.plastic' }) },
        ]
        :[
          { value: CARD_TYPE.VIRTUAL, label: t({ id: 'card.status.virtual' }) }, 
        ]
      }
        onChange={(value) => {
          setSelectedType(value as CardType);
        }}
      />
      {selectedType === CARD_TYPE.VIRTUAL && <VirtualForm onClose={onClose} />}
      {selectedType === CARD_TYPE.PHYSICAL && <PlasticForm setSelectedType={setSelectedType} onClose={onClose} />}
    </Drawer>
  );
};

export default DrawerAddCard;
