import React from 'react';
import classes from './Transactions.module.scss';
import {
  RiverTransactionType,
  TransactionDetailsType,
} from '../../../../types/transactions';
import { Button, Divider, Flex, Modal, Tag, Typography } from 'antd';
import { ActivityIndicator, BlackButton, CenteredContainer } from 'common/components/UI';
import TransactionIcon from './components/TransactionIcon';
import { TRANSACTION_STATUS_TAG, TRANSACTION_TYPE_LOCALIZED } from './model/constants';
import { useIntl } from 'react-intl';
import { getTransactionTypeLabel } from './model/utils';
import { CloseOutlined } from '@ant-design/icons';
import { RIVER_TRANSACTION_STATUS } from 'river/lib/constants/values';
import { ReactComponent as ExclamationIcon } from 'common/assets/svg/exclamation.svg';

const { Text, Title } = Typography;

type TransactionDetailsProps = {
  loading: boolean;
  transaction?: TransactionDetailsType;
  setTransaction: (transaction?: TransactionDetailsType) => void;
  onExportPdf: (transactionId?: string, language?: string) => unknown;
};

const TransactionDetails: React.FC<TransactionDetailsProps> = ({
  loading,
  transaction,
  setTransaction,
  onExportPdf,
}) => {
  const { formatMessage: t } = useIntl();
  const type = transaction?.type || transaction?.online_message?.type;
  const status = transaction?.document?.status || transaction?.status;
  const amount = transaction?.amount;
  const description =
    transaction?.details?.description ||
    transaction?.description ||
    transaction?.online_message?.descriptor;
  const account = transaction?.account;
  const card = transaction?.card;
  console.log('transaction.status', transaction?.status);
  return (
    <Modal
      centered
      closeIcon={<CloseOutlined id="btn__transaction-close" />}
      open={transaction !== undefined}
      onCancel={() => setTransaction()}
      className={classes['transaction-modal']}
      styles={{
        footer: {
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: 20,
        },
      }}
      footer={() => (
        <>
          {status !== RIVER_TRANSACTION_STATUS.DECLINED ? (
            <BlackButton
              disabled={loading}
              id="btn__download-transaction"
              onClick={() => onExportPdf(transaction?.id.toString())}>
              {loading ? (
                <ActivityIndicator />
              ) : (
                <Typography>{t({ id: 'screens.history.single.buttons.pdf' })}</Typography>
              )}
            </BlackButton>
          ) : null}
        </>
      )}>
      {transaction?.id ? (
        <Flex
          vertical
          gap={16}
          justify="center"
          className={classes['transaction-details']}>
          <Text className={classes['transaction-details__header']}>
            {transaction.created ?? transaction.origin_created}
          </Text>
          <Flex vertical className={classes['transaction-details__body']}>
            <Flex vertical align="center" gap={8}>
              <div className={classes['icon-wrap']}>
                {status === RIVER_TRANSACTION_STATUS.DECLINED ? (
                  <ExclamationIcon className={classes.icon} />
                ) : null}
                <TransactionIcon size={48} type={type} status={status} />
              </div>
              <Flex vertical gap={4}>
                <Title level={4} style={{ textAlign: 'center' }}>
                  {amount && status === RIVER_TRANSACTION_STATUS.DECLINED
                    ? '-' + amount
                    : amount && amount < 0
                      ? amount
                      : '+' + amount}{' '}
                  €
                </Title>
                <Text style={{ fontSize: 12 }}>{description}</Text>
              </Flex>
            </Flex>
            <Divider style={{ margin: '20px 0 8px' }} />
            <Flex vertical gap={4}>
              {account && (
                <Flex vertical>
                  <Text className={classes['transaction-details__title']}>
                    {t({ id: 'transactions.fields.account' })}
                  </Text>
                  <Text className={classes['transaction-details__text']}>
                    {transaction?.account?.title} · {transaction?.account?.iban}
                  </Text>
                  <Divider style={{ margin: '4px 0 0' }} />
                </Flex>
              )}
              {card && (
                <Flex vertical>
                  <Text className={classes['transaction-details__title']}>
                    {t({ id: 'transactions.fields.card' })}
                  </Text>
                  <Text className={classes['transaction-details__text']}>
                    {transaction?.card?.cardholder} · {transaction?.card?.number_masked}
                  </Text>
                  <Divider style={{ margin: '4px 0 0' }} />
                </Flex>
              )}
              {transaction?.document?.extra?.iban && (
                <Flex vertical>
                  <Flex vertical>
                    <Text className={classes['transaction-details__title']}>IBAN</Text>
                    <Text>{transaction?.document?.extra?.iban}</Text>
                  </Flex>
                  <Divider style={{ margin: '4px 0 0' }} />
                </Flex>
              )}
              {type && getTransactionTypeLabel(t, type as RiverTransactionType) && (
                <Flex vertical>
                  <Text className={classes['transaction-details__title']}>
                    {t({ id: 'transactions.fields.type' })}
                  </Text>
                  <Text>{getTransactionTypeLabel(t, type as RiverTransactionType)}</Text>
                  <Divider style={{ margin: '4px 0 0' }} />
                </Flex>
              )}
              {status && TRANSACTION_STATUS_TAG[status] && (
                <Flex vertical>
                  <Text className={classes['transaction-details__title']}>
                    {t({ id: 'transactions.fields.status' })}
                  </Text>
                  <Tag
                    style={{ width: 'min-content' }}
                    color={TRANSACTION_STATUS_TAG[status].color}>
                    {t({ id: TRANSACTION_STATUS_TAG[status].title })}
                  </Tag>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <CenteredContainer>
          <ActivityIndicator size="large" />
        </CenteredContainer>
      )}
    </Modal>
  );
};

export default TransactionDetails;
