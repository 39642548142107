import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, MenuProps, Spin } from 'antd';
import classes from './BetweenAccounts.module.scss';
import { BackButton, CenteredContainer, GridContainer } from 'common/components/UI';
import { AppDispatch, RootState } from 'common/store';
import { Account } from '../../../types';
import { sendBetweenAccounts } from '../../../store/actions/transfers';
import { refreshAccounts } from '../../../store/actions/user';
import Beneficiary from './components/Beneficiary';
import Success from './components/Success';
import { mapAccountsToOptions } from '../../../components/AccountDropdown/actions';
import { ERROR_MESSAGES } from '../../../lib/constants/errors';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { getCurrentAccount } from '../../../store/selectors';
import { handleError } from '../../../lib/utils/errorHandler';
import { useIntl } from 'react-intl';
import { getErrorMessage } from './model/errors';

const BetweenAccounts: React.FC = () => {
  const accounts = useSelector((state: RootState) => state.river.accounts);
  const currentAccount = useSelector(getCurrentAccount);

  const dispatch = useDispatch<AppDispatch>();
  const { showError, showSuccess } = useMessage();

  const { formatMessage: t } = useIntl();
  const [form] = Form.useForm();
  const [amount, setAmount] = useState<number | null>(null);
  const [currentFrom, setCurrentFrom] = useState<Account>(currentAccount ? accounts[currentAccount] : Object.values(accounts)[0]);
  const [currentTo, setCurrentTo] = useState<Account>(currentAccount ? accounts[currentAccount] : Object.values(accounts)[0]);
  const [fromOptions, setFromOptions] = useState<MenuProps['items']>(mapAccountsToOptions(accounts, setCurrentFrom, 'account-from'));
  const [toOptions, setToOptions] = useState<MenuProps['items']>(mapAccountsToOptions(accounts, setCurrentTo, 'account-to'));
  const [step, setStep] = useState<number>(0);

  const resetForm = () => {
    setAmount(null);
    setStep(0);
  }

  const handleErrors = (amount: number) => {
    if (currentFrom.id === currentTo.id) {
      showError(t({ id: 'schemas.account.different' }));
      return 1;
    }

    if (amount && (currentFrom.available < amount)) {
      showError(t({ id: ERROR_MESSAGES.NOT_ENOUGH }), 'toast__not-enough-funds');
      return 1;
    }

    return 0;
  };

  const handleBeneficiarySubmit = ({ amount }: {amount: number}) => {
    (async () => {
      setAmount(amount)
      if (handleErrors(amount)) return;

      const body = {
        accountId: currentFrom.id,
        amount: amount ?? 0,
        corrAccountId: currentTo.id,
        description: '',
      };

      try {
        await dispatch(sendBetweenAccounts(body));

        setStep(1);
        await dispatch(refreshAccounts());
      } catch (err) {
        const errorMessage = handleError(err, getErrorMessage, t);
        showError(errorMessage);
      }
    })();
  };

  const steps: Record<number, JSX.Element> = useMemo(
    () => ({
      0: (
        <Beneficiary
          fromOptions={fromOptions}
          toOptions={toOptions}
          currentFrom={currentFrom}
          currentTo={currentTo}
          form={form}
          onSubmit={handleBeneficiarySubmit}
        />
      ),
      1: <Success resetForm={resetForm} amount={amount || 0} />,
    }),
    [currentFrom, currentTo, amount],
  );

  return (
    <GridContainer>
      <div className={classes['wrapper']}>
        <BackButton />
        {steps[step]}
      </div>
    </GridContainer>
  );
};

export default BetweenAccounts;
