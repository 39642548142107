import React from 'react';
import mastercard from 'common/assets/svg/mastercard.svg';
import chip from 'common/assets/svg/chip.svg';
import { Flex, Typography } from 'antd';
import { combineClasses, getGreyColor } from 'common/lib/utils/helpers';
import RoundContainer from '../RoundContainer/RoundContainer';
import { UniversalCardStatus } from 'common/types/cards';
import { CARD_UNIVERSAL_STATUS } from 'common/lib/constants/constants';
import { CloudFilled } from '@ant-design/icons';
import classes from './Card.module.css';
import { useIntl } from 'react-intl';
import {
  ApplicationOverlay,
  BlockedOverlay,
  ClosedOverlay,
  FrozenOverlay,
  SuspendedOverlay,
  InactiveOverlay
} from './overlays';

type CardProps = {
  shortNumber: string;
  bgColor: string;
  size?: 's' | 'm' | 'l';
  selected?: boolean;
  status?: UniversalCardStatus;
  virtual?: boolean;
  onClick?: () => unknown;
  id?: string;
  title?: string;
};

const Card: React.FC<CardProps> = ({
  shortNumber,
  bgColor,
  size = 'm',
  status,
  selected = false,
  virtual = false,
  onClick,
  id,
  title
}) => {
  const isLarge = size === 'l';
  const isMedium = size === 'm'; 

  const renderOverlay = () => {
    switch (status) {
      case CARD_UNIVERSAL_STATUS.FROZEN:
        return <FrozenOverlay />;
      case CARD_UNIVERSAL_STATUS.BLOCKED:
        return <BlockedOverlay />;
      case CARD_UNIVERSAL_STATUS.CLOSED:
        return <ClosedOverlay />;
      case CARD_UNIVERSAL_STATUS.APPLICATION:
        return <ApplicationOverlay />;
      case CARD_UNIVERSAL_STATUS.SUSPENDED:
        return <SuspendedOverlay />;
      case CARD_UNIVERSAL_STATUS.INACTIVE:
        return <InactiveOverlay />;
    }
  };

  return (
    <Flex
      id={id}
      justify="space-between"
      align="flex-end"
      className={combineClasses(
        isLarge ? classes['card-wrapper-l'] : isMedium ? classes['card-wrapper-m'] : classes['card-wrapper-s'],
        selected ? classes.selected : '',
      )}
      style={{ background: bgColor }}
      onClick={onClick}>
      <Typography.Text className={classes['text']}>
        {`${isLarge ? '•' : ''}${shortNumber}`}
      </Typography.Text>
      <div className={classes['card-placeholder']}>{title || ''}</div>
      <img
        width={isLarge ? 64 : 20}
        height={isLarge ? 40 : 12}
        src={mastercard}
        className={classes.img}
      />
      {isLarge &&
        (virtual ? (
          <div id={classes.virtual}>
            <RoundContainer bgColor={getGreyColor(0.12)} padding={8}>
              <CloudFilled style={{ fontSize: 24, color: 'white' }} />
            </RoundContainer>
          </div>
        ) : (
          <img id={classes.chip} width={48} src={chip} />
        ))}
      {renderOverlay()}
    </Flex>
  );
};

export default Card;
