import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Flex, Form, Input, Progress, Select, Space, theme, Typography } from 'antd';
import classes from '../../SignUp.module.scss';
import { getSignUpScheme } from '../../model/scheme';
import { ReactComponent as ExclamationIcon } from 'common/assets/svg/exclamation.svg';
import { ReactComponent as CheckmarkIcon } from 'common/assets/svg/checkmark.svg';
import { APP } from 'common/lib/constants/constants';
import { CreateAccountForm } from 'common/pages/Auth/SignUp/model/types';
import { useIntl } from 'react-intl';
import { general } from '../../../../../lib/schemas/rules';
import { ReactComponent as ArrowLeftIcon } from 'common/assets/svg/arrow-left.svg';

interface PasswordFormProps {
  formValues: Partial<CreateAccountForm>;
  toPrevStep: () => void;
}
const PasswordForm: React.FC<PasswordFormProps> = ({formValues, toPrevStep}) => {
  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    hasMixedCase: false,
    hasDigit: false
  });

  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const { formatMessage: t } = useIntl();
  const formRules = getSignUpScheme(t);

  const [validationErrors, setValidationErrors] = useState<{[key: string]: string[]}>({
    password: [],
    confirmPassword: []
  })

  const onFieldsChange = () => {
    setValidationErrors(prevState => ({
      ...prevState,
      password: form.getFieldError('password'),
      confirmPassword: form.getFieldError('confirmPassword'),
    }))
  }

  const validatePassword = (password: string = '') => {
    setPasswordValidations({
      minLength: password.length >= 8,
      hasMixedCase: /(?=.*[a-z])(?=.*[A-Z])/.test(password),
      hasDigit: /[0-9]/.test(password)
    });
  };

  useEffect(() => {
    if (formValues.password) {
      validatePassword(formValues.password);
    }
  }, [formValues.password]);
  const getValidationIcon = (isValid: boolean) => {
    return isValid ? <CheckmarkIcon className={classes.icon} style={{ color: token.colorSuccess }} />
      : <ExclamationIcon className={classes.icon} style={{ color: token.colorError }} />;
  };

  return (
    <Form initialValues={{
      ...formValues,
      termsAndConditions: true,
      privacyPolicy: true,
    }} form={form} onValuesChange={(changedValues: { password?: string }) => {
      if ('password' in changedValues) {
        validatePassword(changedValues.password);
      }
    }} validateTrigger="onChange" onFieldsChange={onFieldsChange} name="step3">
      
      <Typography.Title className={classes.title}>
      <div onClick={toPrevStep} className={classes.arrowLeftIcon}>
          <ArrowLeftIcon style={{ height: 22, width: 22 }} />
        </div>
        {t({id:'inputs.password'})}</Typography.Title>
      <Typography.Text className={classes.subTitle}>
        {t({id:'password.hint'})}
      </Typography.Text>
      <Progress percent={60} rootClassName={classes.progressBar} showInfo={false} />
      <Form.Item help={<div id='error__password'>{validationErrors.password[0]}</div>} rules={formRules.password} name="password">
        <Input.Password placeholder={t({id:'inputs.password'})}/>
      </Form.Item>
      <Form.Item rules={[...formRules.confirmPassword, ({getFieldValue}) => ({
        validator(_, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject(t({id:'schemas.password.match'}));
        }
      })]} help={<div id='error__confirm-password'>{validationErrors.confirmPassword[0]}</div>} dependencies={['password']} name="confirmPassword">
        <Input.Password placeholder={t({id:'screens.auth.activate.inputs.confirmPassword'})}/>
      </Form.Item>
      <Form.Item>
        <Space direction="vertical">
          <Space className={classes.hintContainer} align="start">
            {getValidationIcon(passwordValidations.hasMixedCase)}
            <Typography.Paragraph className={classes.hintTitle}>{t({id:'password.hint1'})}</Typography.Paragraph>
          </Space>
          <Space className={classes.hintContainer} align="start">
            {getValidationIcon(passwordValidations.hasDigit)}
            <Typography.Paragraph className={classes.hintTitle}>{t({id:'password.hint2'})}</Typography.Paragraph>
          </Space>
          <Space className={classes.hintContainer} align="start">
            {getValidationIcon(passwordValidations.minLength)}
            <Typography.Paragraph className={classes.hintTitle}>{t({id:'password.hint3'})}</Typography.Paragraph>
          </Space>
        </Space>
      </Form.Item>
      <Form.Item rules={[
        {
          validator: (_, value) =>
            value ? Promise.resolve() : Promise.reject(new Error(t({id: 'schemas.new.required-field'}))),
        },
      ]} valuePropName="checked" name="privacyPolicy">
        <Checkbox id='btn__checkbox'>
          {t({id:'screens.auth.signup.private.inputs.agreeMonitored'})}
        </Checkbox>
      </Form.Item>

      <Button id='btn__next' onClick={form.submit} type="primary" block>{t({id:'buttons.next'})}</Button>
    </Form>
  );
};

export default PasswordForm;