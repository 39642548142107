import { BackButton, BlackButton, GridContainer } from 'common/components/UI';
import { Flex, Form, InputNumber, MenuProps, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import classes from './TopUpInvoice.module.scss';
import { getAccounts, getCurrentAccount, getCurrentAccountObject } from '../../../store/selectors';
import { Account, Card } from '../../../types';
import { useIntl } from 'react-intl';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import { CARD_STATUS } from '../../../lib/constants/values';
import { general, generalFields } from '../../../../common/lib/schemas/rules';
import { downloadInvoice } from '../../../store/actions/transactions';
import { mapCardsToOptions } from '../../../components/CardDropdown/actions';
import CardDropdown from '../../../components/CardDropdown/CardDropdown';
import { AppDispatch, RootState } from "../../../../common/store";
import AccountDropdown from 'river/components/AccountDropdown/AccountDropdown';
import { mapAccountsToOptions } from 'river/components/AccountDropdown/actions';

const { Title, Text } = Typography;

const TopUpInvoice: React.FC = () => {
  const riverAccounts = useSelector(getAccounts);
  const accounts = useSelector((state: RootState) => state.river.accounts);
  const currentAcc = useSelector(getCurrentAccount);
  const [currentFrom, setCurrentFrom] = useState<Account>(currentAcc ? accounts[currentAcc] : Object.values(accounts)[0]);
  const [fromOptions, setFromOptions] = useState<MenuProps['items']>(mapAccountsToOptions(accounts, setCurrentFrom, 'account-from'));
  const riverCards: Card[] = [];
  for (const acc of Object.values(riverAccounts)) {
    acc.cards?.map(card => {
      if (card.status === CARD_STATUS.ACTIVE || card.status === CARD_STATUS.BLOCKED)
        riverCards.push(card)
    });
  }
  const currentAccount = useSelector(getCurrentAccountObject);

  const { formatMessage: t } = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { showError, showSuccess } = useMessage();
  const [form] = Form.useForm();

  const [formData, setFormData] = useState<Partial<{amount: number}>>()
  const [currentOption, setCurrentOption] = useState<Card>(currentAccount.cards && currentAccount.cards?.length !== 0 ? currentAccount.cards[0] : riverCards[0]);
  const [options, setOptions] = useState<MenuProps['items']>(mapCardsToOptions(riverCards, setCurrentOption));
  const [validationError, setValidationError] = useState<string[]>([])

  const onFieldsChange = () => {
    setValidationError(form.getFieldError('amount'));
  };

  const onFinish = async (values: {amount: number}) => {
    try {
      await dispatch(downloadInvoice(currentFrom.id, values.amount));
      showSuccess(t({id: 'common.message.success'}), 'toast__invoice-success');
    } catch (err: any) {
      showError(t({id: 'common.message.error'}), 'toast__invoice-error');
    }
  }

  return (
    <GridContainer>
      <Flex vertical>
        <BackButton />
        <Flex vertical gap={32} className={classes['wrapper']}>
          <Title level={3}>{t({ id: 'sections.load.invoice.title' })}</Title>
          {/* <CardDropdown
            label={`${t({ id: 'components.cardsDropdown.header' })}:`}
            options={options}
            currentOption={currentOption}
            id='card'
          /> */}
          <AccountDropdown
            label={`${t({ id: 'components.cardsDropdown.header' })}:`}
            options={fromOptions}
            currentOption={currentFrom}
            id='card'
          />
          <Text>{t({ id: 'sections.load.invoice.text' })}</Text>
          <Form form={form} initialValues={formData} onFinish={onFinish} onFieldsChange={onFieldsChange}>
            <Form.Item name='amount' help={<div id="error__invoice-amount">{validationError[0]}</div>} rules={[
              general(t)['required-field'],
              ...generalFields(t)['amount']
            ]}>
              <InputNumber
                placeholder={t({id: 'noty.card.amount'})}
              />
            </Form.Item>
            <BlackButton id='btn__generate-invoice' type='primary' htmlType='submit'>
              {t({ id: 'sections.load.invoice.btn' })}
            </BlackButton>
          </Form>
        </Flex>
      </Flex>
    </GridContainer>
  );
};

export default TopUpInvoice;