import React, { useCallback, useEffect, useState } from 'react';
import { BugOutlined, CloseOutlined } from '@ant-design/icons';
import ListItem from '../ListItem';
import { UserT } from 'common/types/user';
import { Drawer, Flex, Input, Upload, Typography } from 'antd';
import type { UploadProps } from 'antd';
import userApi from 'common/api/user';
import { useIntl } from 'react-intl';
import BlackButton from '../../../../components/UI/BlackButton/BlackButton';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { useMessage } from "../../../../lib/hooks/useMessage/useMessage";

type BugReportProps = {
  userInfo: Partial<UserT>;
};

const { TextArea } = Input;
const { Dragger } = Upload;
const { Text } = Typography;

const BugReport = ({ userInfo }: BugReportProps) => {
  const { formatMessage: t } = useIntl();
  const { showError, showSuccess } = useMessage();

  const [open, setOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const [textError, setTextError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // const [clientIP, setClientIP] = useState<boolean>(false);
  const [subject, setSubject] = useState<string>('');
  const [subjectError, setSubjectError] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<any[]>([]);

  useEffect(() => {
    // getIPData()
  }, []);

  const handleTextChange = useCallback((value: string) => {
    const pattern = /^[A-Za-z0-9\s]+$/;
    if (value === '' || pattern.test(value)) {
    setText(value);
    setTextError(false);
    }
  }, []);

  const handleSubjectChange = useCallback((value: string) => {
    if (!'[$&+,:;=?@#|\'<>.^*()%!_№~`"'.includes(value.slice(-1)) || value === '') {
      setSubject(value);
      setSubjectError(false);
    }

  }, []);

  const dummyRequest = (options: UploadRequestOption<any>) => {
    setTimeout(() => {
      if (options.onSuccess != undefined)
        options.onSuccess('ok');
    }, 0);
  };

  const props: UploadProps = {
    name: 'file',
    multiple: true,
    maxCount: 3,
    id: 'bug-report-upload',
    fileList: attachments,
    onChange(info) {
      const { status } = info.file;
      if (status === 'done' || status === 'removed' || status === 'uploading') {
        let size: number = 0;
        info.fileList.forEach((file) => (size += file.size ?? 0));
        const mbSize = convertBytesToMb(size);

        if (mbSize > 5) {
          showError(t({ id: 'screens.settings.bug.report.fileSizeValidationError' }));
        } else {
          setAttachments(info.fileList);
        }
      }
    },
    listType: 'picture',
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    customRequest: (options: UploadRequestOption) => dummyRequest(options),
    beforeUpload: (file) => {
      const isImg =
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg';
      if (!isImg) {
        showError(t({ id: 'screens.settings.bug.report.fileSizeValidationError' }));
      }
      return isImg || Upload.LIST_IGNORE;
    },
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onCloseDrawer = () => {
    setOpen(false);
  };

  const getOSInformation = () => {
    const userAgent = navigator.userAgent;
    let browser;
    let browserVersion;
    const os = navigator.platform;

    if (userAgent.includes('Firefox/')) {
      browser = 'Firefox';
      browserVersion = `v${userAgent.split('Firefox/')[1]}`;
    } else if (userAgent.includes('Edg/')) {
      browser = 'Edg';
      browserVersion = `v${userAgent.split('Edg/')[1]}`;
    } else if (userAgent.includes('Chrome/')) {
      browser = 'Chrome';
      browserVersion = `v${userAgent.split('Chrome/')[1]}`;
    } else if (userAgent.includes('Safari/')) {
      browser = 'Safari';
      browserVersion = `v${userAgent.split('Safari/')[1]}`;
    }

    return {
      os,
      browser,
      browser_version: browserVersion,
    };
  };

  const convertBytesToMb = (bytes: number) => bytes / 1024 ** 2;

  // TODO: Add IP data to 'device' field in request
  const getIPData = async () => {
    // const res = await Index.get('https://geolocation-db.com/json/');
    // setClientIP(res.data.IPv4);
  };

  const onSubmit = () => {
    const osInfo = getOSInformation();
    const device =
      'os: ' +
      osInfo.os +
      '\n' +
      'browser: ' +
      osInfo.browser +
      '\n' +
      'browser_version: ' +
      osInfo.browser_version +
      '\n' +
      'login_method: password' +
      '\n';

    const bugReportForm = new FormData();
    bugReportForm.append('email', userInfo.email ?? 'No email');
    bugReportForm.append('subject', subject);
    bugReportForm.append('description', text);
    bugReportForm.append('source', 'WEB');
    bugReportForm.append('device', device);

    attachments.forEach((file: File) => {
      bugReportForm.append('attachments[]', file);
    });

    setLoading(true);
    userApi.settings
    .reportProblem(bugReportForm)
    .then((res) => {
      showSuccess(
        t({ id: 'screens.settings.bug.report.success' }),
        'toast__bug-report-success'
      );
      setSubjectError(false);
      setTextError(false);
      setSubject('');
      setText('');
      setAttachments([]);
    })
    .catch((err) => {
      showError(
        t({ id: 'screens.settings.bug.report.request.error' }),
        'toast__bug-report-error'
      );
    })
    .finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <div onClick={showDrawer} id='settings__bug-report'>
        <ListItem
          title={t({ id: 'screens.setting.setting.setting.nav.bugReport' })}
          iconComponent={
            <BugOutlined
              style={{
                fontSize: 24,
                padding: 12,
                boxSizing: 'content-box',
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 100,
              }}
            />
          }
        />
      </div>
      <Drawer
        title={t({ id: 'screens.setting.setting.setting.nav.bugReport' })}
        placement="right"
        width={512}
        closeIcon={<CloseOutlined id='btn__bug-report-close'/>}
        onClose={onCloseDrawer}
        open={open}
      >
        <div>
          <Flex justify="center" align="center" vertical>
            <BugOutlined
              style={{
                fontSize: 36,
                padding: 18,
                boxSizing: 'content-box',
                background: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 100,
                marginBottom: 32,
              }}
            />
            <Input
              id='bug-report-subject'
              placeholder={t({ id: 'screens.settings.bug.report.subject.placeholder' })}
              style={{ marginBottom: 8 }}
              value={subject}
              onChange={(e) => handleSubjectChange(e.target.value)}
            />
            {subjectError && (
              <Text>
                {t({ id: 'screens.profile.aboutSection.section.subject.error' })}
              </Text>
            )}
            <TextArea
              id='bug-report-text'
              style={{ marginBottom: 32 }}
              autoSize={{
                minRows: 3,
                maxRows: 5,
              }}
              onChange={e => handleTextChange(e.target.value)}
              placeholder={t({ id: 'screens.settings.bug.report.placeholder' })} 
              value={text}
            />
            <Dragger {...props}>
              <p
                className="ant-upload-text"
                style={{ textAlign: 'left', fontWeight: 700 }}>
                <u>{t({ id: 'screens.settings.bug.report.add.attachment' })}</u>
              </p>
              <p className="ant-upload-hint" style={{ textAlign: 'left' }}>
                {t({ id: 'screens.settings.bug.report.add.attachment.description' })}
              </p>
            </Dragger>
            <BlackButton
              id='btn__send-bug-report'
              disabled={!text || !subject}
              loading={loading}
              type="primary"
              style={{ marginTop: 32 }}
              onClick={onSubmit}>
              {t({ id: 'buttons.send' })}
            </BlackButton>
          </Flex>
        </div>
      </Drawer>
    </>
  );
};

export default BugReport;
