import { BlockReason } from 'river/types';
import { APP } from '../../../common/lib/constants/constants';

export const PLAN_NAME: string = `${APP.NAME} PRO`;

export const BIC = 'UAWALT21XXX';

export const ONLY_PLASTIC_RULES = [
  'contactless_disabled',
  'atm_disabled',
  'magnet_stripe_disabled',
];

export const ENDPOINTS = {
  VERIFICATION_CTRL: {
    GET_TOKEN: 'private/verification/access',
    GET_STATUS: 'private/verification/get-applicant-data',
    UPDATE_TIN: 'private/verification/tin'
  },
  CARD_CTRL: {
    PC_GET_CARD: 'private/river/card/physical',
    PC_ACTIVATE_CARD: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/physical/activate/${cardId}`,
    PC_ACTIVATE_CARD_SIGN: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/physical/activate/${cardId}/sign`,
    PC_ACTIVATE_CARD_SIGN_ADVANCED: (accountId: number | string, applicationId: number | string) =>
      `private/river/${accountId}/card/physical/activate/${applicationId}/advanced/sign`,
    PC_ACTIVATE_CARD_RESEND: (accountId: number | string, applicationId: number | string) =>
      `private/river/${accountId}/card/physical/${applicationId}/resend-token`,
    GET_APPLICATION_LOCATIONS: 'private/river/card/applications/locations',
    GET_APPLICATIONS: 'private/river/card/applications',
    PC_APPLICATION: (accountId: number) =>
      `private/river/${accountId}/card/physical/application`,
    PC_REPLACE_CARD: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/physical/${cardId}/replace`,
    VC_RESEND_TOKEN: (accountId: number, cardId: string) =>
      `private/river/${accountId}/card/virtual/${cardId}/resend-token`,
    VC_CREATE_APPLICATION: (accountId: number) =>
      `private/river/${accountId}/card/virtual/create`,
    VC_REPLACE_CARD: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/virtual/${cardId}/replace`,
    VC_SIGN_APPLICATION: (accountId: number, cardId: string) =>
      `private/river/${accountId}/card/virtual/sign/`,
    GET_CARD_INFO: (accountId: number, cardId: string) =>
      `private/river/${accountId}/card/${cardId}`,
    BLOCK_CARD: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/${cardId}/block/manual`,
    SEND_ENROLL_TOKEN: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/${cardId}/enroll`,
    RESEND_ENROLL_TOKEN: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/${cardId}/enroll/resend-token`,
    SIGN_ENROLL_CARD: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/${cardId}/enroll/sign`,
    SEND_GET_PIN_TOKEN: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/${cardId}/get-pin`,
    RESEND_GET_PIN_TOKEN: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/${cardId}/get-pin/resend-token`,
    SIGN_GET_PIN: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/${cardId}/get-pin/sign`,
    HARD_BLOCK_CARD: (
      accountId: number | string,
      cardId: string | number,
      reason: BlockReason,
    ) => `private/river/${accountId}/card/${cardId}/hard-block?reason=${reason}`,
    GET_CARD_NUMBER_AND_CVN: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/${cardId}/info`,
    GET_CARD_RULES: (accountId: number, cardId: string | number) =>
      `private/river/${accountId}/card/${cardId}/rules`,
    SET_CARD_RULE: (accountId: number, cardId: string | number) =>
      `private/river/${accountId}/card/${cardId}/set-rule`,
    UNBLOCK_CARD: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/${cardId}/unblock/manual`,
    UNENROLL_CARD: (accountId: number | string, cardId: number | string) =>
      `private/river/${accountId}/card/${cardId}/unenroll`,
    GET_CARDS: (accountId: number) =>
      `private/river/${accountId}/cards?limit=10&offset=0`,
  },
  USER_CTRL: {
    TEST_WALLETO: 'open/test-walletto',
    GET_CLIENT_DATA: 'private/river/client',
    CREATE_ACCOUNT: (title: string) => `private/river/create-account?title=${title}`,
    CREATE_PRIVATE_CLIENT: 'private/river/create-private-client',
    GET_ACCOUNTS: 'private/river/get-accounts',
    GET_ACCOUNT_DETAILS: (accountId: number) =>
      `private/river/${accountId}/get-account-details`,
    UPDATE_ACCOUNT_TITLE: (accountId: number, title: string) => {
      return `private/river/${accountId}/update-account-title?title=${encodeURI(title)}`;
    },
  },
  TRANSACTION_CTRL: {
    GET_HOLD_BY_ID: (accountId: number, transactionId: string) =>
      `private/river/${accountId}/hold/${transactionId}`,
    GET_HOLDS: (accountId: number) => `private/river/${accountId}/holds`,
    DOWNLOAD: (accountId: number, fileExt: string) =>
      `private/river/${accountId}/transaction/${fileExt.toLowerCase()}`,
    GET_TRANSACTION_BY_ID: (accountId: number, transactionId: string) =>
      `private/river/${accountId}/transaction/${transactionId}`,
    DOWNLOAD_TRANSACTION_PDF: (
      accountId: number,
      transactionId: string,
      language = 'EN',
    ) =>
      `private/river/${accountId}/transaction/${transactionId}/pdf?language=${language}`,
    GET_TRANSACTIONS: (accountId: number) => `private/river/${accountId}/transactions`,
    DOWNLOAD_PDF_INVOICE: (cardId: number) => `private/invoice/${cardId}/sepa-walletto/pdf`
  },
  TRANSFER_CTRL: {
    CONFIRM_SEPA_PAYMENT: (accountId: number, paymentId: number, amount: number) =>
      `private/river/payment/${accountId}/sepa/confirm/${paymentId}?amount=${amount}`,
    CREATE_SEPA_PAYMENT: (accountId: number) =>
      `private/river/payment/${accountId}/sepa/create`,
    SIGN_SEPA_PAYMENT: (accountId: number, paymentId: number) =>
      `private/river/payment/${accountId}/sepa/sign/${paymentId}`,
    RESEND_TOKEN: (accountId: number, paymentId: number) =>
      `private/river/payment/${accountId}/sepa/${paymentId}/resend-token`,
    TRANSFER: 'private/river/transfer',
  },
  TEMPLATES_CTRL: {
    CREATE: 'private/river/template/create',
    CREATE_FROM_PAYMENT: 'private/river/template/create-from-payment',
    DELETE: (id: string) => `private/river/template/delete/${id}`,
    LIST: 'private/river/template/list',
    RECURRING_PAYMENTS: (id: string) => `private/river/template/recurring-payments/${id}`,
    UPDATE: (id: string) => `private/river/template/update/${id}`,
    INFO: (id: string) => `private/river/template/${id}`,
  },
};

export const VERIFICATION_STATUS = {
  INIT: 'INIT',
  NOT_STARTED: 'NOT_STARTED',
  STARTING: 'STARTING',
  PENDING: 'PENDING',
  PRECHECKED: 'PRECHECKED',
  QUEUED: 'QUEUED',
  ONHOLD: 'ONHOLD',
  DECLINED: 'DECLINED',
  RETRY: 'RETRY',
  COMPLETED: 'COMPLETED',
};

export const ACCOUNT_STATUS = {
  ACTIVE: 'active',
  NEW: 'new',
  INACTIVE: 'inactive',
  CLOSED: 'closed',
  PENDING: 'pending',
  REJECTED: 'rejected',
};

export const RIVER_TRANSACTION_STATUS = {
  APPROVED: 'approved',
  PENDING: 'pending',
  DECLINED: 'declined',
} as const;

export const RIVER_TRANSACTION_TYPE = {
  TRANSFER_CREDIT: 'transfer_credit',
  SEND_CREDIT: 'send_credit',
  TRANSFER_DEBIT: 'transfer_debit',
  SEND_DEBIT: 'send_debit',
  WITHDRAW_SEPA: 'withdraw_sepa',
  DEPOSIT: 'deposit',
  WITHDRAW: 'withdraw',
  CLEARING_FEE: 'clearing_fee',
  CLEARING_DEBIT: 'clearing_debit',
  BANK_FEE: 'bank_fee',
  DEPOSIT_SEPA: 'deposit_sepa',
  WITHDRAW_SEPA_CANCEL: 'withdraw_sepa_cancel',
} as const;

export const CARD_TYPE = {
  PHYSICAL: 'plastic',
  VIRTUAL: 'virtual',
} as const;

export const CARD_STATUS = {
  SOFT_BLOCKED: 'soft_blocked',
  BLOCKED: 'blocked',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  CLOSED: 'closed',
  SUSPENDED: 'suspended',
  APPLICATION: 'application',
} as const;

export const BLOCK_REASON = {
  CUSTOMER_CLOSED: 'CUSTOMER_CLOSED',
  PICK_UP: 'PICK_UP',
  LOST: 'LOST',
  STOLEN: 'STOLEN',
  AT_BANK_WILL: 'AT_BANK_WILL',
  AT_CARDHOLDER_WILL: 'AT_CARDHOLDER_WILL',
} as const;
