import React from 'react';
import { RiverTransactionStatus, Transaction } from '../../../../types/transactions';
import { Button, Divider, Flex, Typography } from 'antd';
import moment from 'moment';
import classes from './Transactions.module.scss';
import { BlackButton } from 'common/components/UI';
import { getUserLocale } from 'common/store/selectors/app';
import { Locale } from 'common/locales/types';
import { getMomentLocale } from 'common/lib/tools/moment';
import TransactionIcon from './components/TransactionIcon';
import {
  RIVER_TRANSACTION_STATUS,
  RIVER_TRANSACTION_TYPE,
} from '../../../../lib/constants/values';
import { useIntl } from 'react-intl';
import { getTransactionTypeLabel } from './model/utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SwapOutlined } from '@ant-design/icons';
import { ReactComponent as ExclamationIcon } from 'common/assets/svg/exclamation.svg';

require('common/lib/tools/momentLocales');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const _ = require('lodash');

const { Text } = Typography;

export type TransactionItem = {
  date: string;
  transactions: Transaction[];
};

type TransactionsListProps = {
  transactions: TransactionItem[];
  loading: boolean;
  canLoadMore: boolean;
  onLoadMore: () => void;
  onDataSelect: (id: string) => void;
};

const TransactionsList: React.FC<TransactionsListProps> = ({
  transactions,
  loading,
  onLoadMore,
  canLoadMore,
  onDataSelect,
}) => {
  const { formatMessage: t } = useIntl();
  const userLocale = useSelector(getUserLocale);
  const navigate = useNavigate();
  moment.locale(getMomentLocale(userLocale as Locale));

  const isPayAgainAvailable = (transaction: Transaction) => {
    return (
      (transaction.type === RIVER_TRANSACTION_TYPE.WITHDRAW_SEPA ||
        transaction.type === RIVER_TRANSACTION_TYPE.SEND_DEBIT) &&
      (transaction.status === RIVER_TRANSACTION_STATUS.PENDING ||
        transaction.status === RIVER_TRANSACTION_STATUS.APPROVED) &&
      transaction.iban
    );
  };

  const formatAmount = (amount: number, status: RiverTransactionStatus) => {
    return status === RIVER_TRANSACTION_STATUS.DECLINED
      ? `-${Number(amount).toFixed(2)}\u00A0€`
      : amount < 0
        ? `${Number(amount).toFixed(2)}\u00A0€`
        : `+${Number(amount).toFixed(2)}\u00A0€`;
  };

  const payAgain = (item: Transaction) => {
    navigate('/pro/send/sepa', { state: { currentTemplate: item } });
  };

  const renderTransactionItem = (item: TransactionItem, index: number) => {
    return (
      <Flex vertical>
        <Text strong>{moment(item.date, 'DD-MM-YYYY').format('LL')}</Text>
        {item.transactions.map((transaction, idx) => {
          const transactionTypeLabel = getTransactionTypeLabel(t, transaction.type);

          return (
            <Flex
              id={'transaction' + idx}
              key={transaction.id}
              onClick={() => onDataSelect(transaction.id)}
              vertical
              className={classes['transaction']}>
              <Flex justify="space-between" align="center" style={{ padding: '8px' }}>
                <Flex gap={12} align="center">
                  <div className={classes['icon-wrap']}>
                    <TransactionIcon
                      size={40}
                      type={transaction.type}
                      status={transaction.status}
                    />
                    {transaction.status === RIVER_TRANSACTION_STATUS.DECLINED ? (
                      <ExclamationIcon className={classes.icon} />
                    ) : null}
                  </div>
                  <Flex vertical gap={2}>
                    <Text className={classes['transaction__recipient']}>
                      {_.startCase(transaction.description || transaction.details)}
                    </Text>
                    <Text className={classes['transaction__description']}>
                      {transactionTypeLabel} · {transaction.created?.slice(10)}
                    </Text>
                    {isPayAgainAvailable(transaction) && (
                      <Button
                        icon={<SwapOutlined style={{ fontSize: 14 }} />}
                        size="small"
                        type="link"
                        className={classes['pay-again']}
                        onClick={(event) => {
                          event.stopPropagation();
                          payAgain(transaction);
                        }}>
                        {t({ id: 'mobile.payments.button.payAgain' })}
                      </Button>
                    )}
                  </Flex>
                </Flex>
                <Text
                  className={classes['no-break']}
                  strong
                  type={
                    transaction.status === RIVER_TRANSACTION_STATUS.DECLINED ? 'danger' : transaction.status === RIVER_TRANSACTION_STATUS.PENDING
                      ? 'secondary'
                      : transaction.amount < 0
                        ? 'danger'
                        : 'success'
                  }>
                  {formatAmount(transaction.amount, transaction.status)}
                </Text>
              </Flex>
              <Divider style={{ margin: 0 }} />
            </Flex>
          );
        })}
        {index === transactions.length - 1 && canLoadMore && (
          <BlackButton onClick={onLoadMore} style={{ alignSelf: 'center', marginTop: 8 }}>
            {t({ id: 'screens.exchange.transactionlist.loadmore' })}
          </BlackButton>
        )}
      </Flex>
    );
  };

  return (
    <Flex vertical gap={16}>
      {transactions.map((t, idx) => renderTransactionItem(t, idx))}
    </Flex>
  );
};

export default TransactionsList;
