import { getSendSepaScheme } from "../../Sepa/model/scheme";
import { general, generalFields } from "../../../../../common/lib/schemas/rules";

export const getTemplateScheme = (
  t: (descriptor: object, values?: object) => string,
) => ({
  ...getSendSepaScheme(t),
  title: [
    general(t)['required-field']
  ]
});