import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  getErrorDefaultMessage,
  getLinearGradientString,
} from 'common/lib/utils/helpers';
import { CARD_UNIVERSAL_STATUS } from 'common/lib/constants/constants';
import { hideLoader, showLoader } from 'common/store/actions/app';
import CardsLayout from 'common/components/layout/CardsLayout/Cards';

import { COLORS } from 'river/lib/constants/styles';
import {
  getAccountAddressString,
  getAccountBalance,
  getAccountIsSoftBlocked,
  getCards,
  getIsSoftBlocked,
} from 'river/store/selectors';
import {
  setCurrentCard,
  showSoftBlockModal,
  updateCard,
} from 'river/store/actions/cards';
import { setCurrentAccount } from 'river/store/actions/user';
import cardApi from 'river/api/card';
import { RiverThunkDispatch } from 'river/types/thunk';
import { getUniversalCard } from 'river/lib/utils/card';

import ManageCard from './blocks/ManageCard/ManageCard';
import FreezeModal from 'common/modals/FreezeModal';
import DrawerGetPIN from './drawers/GetPIN/GetPIN';
import DrawerEnroll from './drawers/Enroll/Enroll';
import DrawerActivate from './drawers/Activate/Activate';
import { useMessage } from 'common/lib/hooks/useMessage/useMessage';
import ReplaceModal from 'common/modals/ReplaceModal';
import { replaceCard } from './actions';
import { refreshRiver } from 'river/store/actions';

const Card = () => {
  const { id: accountId, cardId = '' } = useParams();
  const navigate = useNavigate();
  const dispatch: RiverThunkDispatch = useDispatch();
  const { formatMessage: t } = useIntl();
  const { showSuccess, showError, showInfo } = useMessage();
  const [modals, setModals] = useState({
    freeze: false,
    replace: false,
  });
  const [drawers, setDrawers] = useState({
    pin: false,
    enroll: false,
    activate: false,
  });

  const currentCardId = cardId || 0;
  const currentAccountId = accountId || 0;

  const cards = useSelector(getCards);
  const currentCardFromStore = cards.find(
    ({ id }) => String(id) === String(currentCardId),
  );

  const formattedCards = cards.map((card) => getUniversalCard(card));
  const [currentCardIndex, setCurrentCardIndex] = useState(
    formattedCards.findIndex(({ id }) => String(id) === String(cardId)),
  );

  const currentCardObject = formattedCards[currentCardIndex];
  const isFrozen = currentCardObject.universalCardStatus === CARD_UNIVERSAL_STATUS.FROZEN;
  const isVirtual = currentCardObject.isVirtual;
  const isApplication =
    currentCardObject.universalCardStatus === CARD_UNIVERSAL_STATUS.APPLICATION;

  const applicationAddress = useSelector(getAccountAddressString);
  const currentAccountBalance = useSelector(getAccountBalance);
  const isSoftBlock = useSelector(getIsSoftBlocked);
  const accountSoftBlocked = useSelector(getAccountIsSoftBlocked);

  const onChangeSelectedCard = (index: number) => {
    setCurrentCardIndex(index);
    // const currentId = currentCardObject.id;
    // navigate('/standart/card/' + currentId);
  };
 
  const freezeCard = async () => {
    if (currentAccountId) {
      dispatch(showLoader());
      await cardApi
        .blockCard(currentAccountId, currentCardId)
        .then(({ data: { response } }) => {
          dispatch(updateCard(currentAccountId, response));
          onCloseFreezeModal();
          showSuccess(
            t({ id: 'river.views.plan.freeze' }),
            'toast__freeze-success',
          );
        })
        .catch(() => showError(t({ id: 'common.message.error' }), 'toast__freeze-error'))
        .finally(() => {
          dispatch(hideLoader());
        });
    }
  };
  const unfreezeCard = async () => {
    if (currentAccountId) {
      dispatch(showLoader());
      await cardApi
        .unblockCard(currentAccountId, currentCardId)
        .then(({ data: { response } }) => {
          dispatch(updateCard(currentAccountId, response)),
          showSuccess(
            t({ id: 'river.views.plan.unfrozen' }), 
            'toast__freeze-success',
          )
          }
        )
        .finally(() => {
          dispatch(hideLoader());
        });
    }
  };

  const onUnfreeze = () => {
    dispatch(showLoader());
    unfreezeCard()
      .then(() => {
        // showSuccess(t({ id: 'common.message.success' }), 'toast__unfreeze-success');
      })
      .catch(() => {
        showError(t({ id: 'common.message.error' }), 'toast__unfreeze-error');
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onOpenFreezeModal = () => setModals((prev) => ({ ...prev, freeze: true }));
  const onCloseFreezeModal = () => setModals((prev) => ({ ...prev, freeze: false }));

  const fetchCardInfo = useCallback(() => {
    if (currentCardFromStore && !currentCardFromStore.cvv && !isApplication)
      cardApi
        .getCardNumberAndCVN(currentAccountId, currentCardId)
        .then(({ data: { response: cardInfo } }) => {
          dispatch(
            updateCard(currentAccountId, {
              ...currentCardFromStore,
              cvv: cardInfo.cvn,
              numberFull: String(cardInfo.number),
            }),
          );
        })
        .catch((e) => console.log(e));
  }, [currentAccountId, currentCardFromStore, currentCardId, dispatch, isApplication]);

  const onBlock = () => {
    navigate(window.location.pathname + '/block');
  };

  const onOpenGetPIN = () => setDrawers((prev) => ({ ...prev, pin: true }));
  const onCloseGetPIN = () => setDrawers((prev) => ({ ...prev, pin: false }));

  useEffect(() => {
    if (!cardId || !accountId || !cards.find(({ id }) => String(id) === String(cardId))) {
      navigate(-1);
      return;
    }
    dispatch(setCurrentCard(cardId));
    dispatch(setCurrentAccount(accountId));
    fetchCardInfo();
  }, [accountId, cardId, cards, dispatch, fetchCardInfo, navigate]);

  const onOpenEnroll = () => setDrawers((prev) => ({ ...prev, enroll: true }));
  const onCloseEnroll = () => setDrawers((prev) => ({ ...prev, enroll: false }));
  const onUnenroll = () => {
    dispatch(showLoader());
    cardApi
      .unenrollCard(currentAccountId, currentCardId)
      .then(({ data: { response: card } }) => {
        dispatch(updateCard(currentAccountId, card));
        showSuccess(t({ id: 'common.message.success' }));
      })
      .catch(() => {
        showError(t({ id: 'common.message.error' }));
      })
      .finally(() => {
        dispatch(hideLoader());
      });
  };

  const onOpenActivate = () => {
    if (isSoftBlock || accountSoftBlocked) {
      dispatch(showSoftBlockModal());
      return;
    }
    setDrawers((prev) => ({ ...prev, activate: true }));
  };
  const onCloseActivate = () => setDrawers((prev) => ({ ...prev, activate: false }));

  const onOpenReplace = () => setModals((prev) => ({ ...prev, replace: true }));
  const onCloseReplace = () => setModals((prev) => ({ ...prev, replace: false }));
  const onReplace = () => {
    if (currentCardFromStore) {
      dispatch(showLoader());
      replaceCard(currentAccountId, currentCardFromStore, 'DEUTSCHE_POST_PACKET')
        .then(() => {
          showSuccess(t({ id: 'common.message.success' }));
          navigate('/');
          dispatch(refreshRiver());
        })
        .catch(({ response }) => {
          const message =
            getErrorDefaultMessage(response) ||
            t({ id: 'card.blocked.replace.error.title' });
          showError(message);
        })
        .finally(() => {
          dispatch(hideLoader());
        });
    }
  };

  return (
    <>
      <CardsLayout
        cards={formattedCards}
        color={getLinearGradientString(COLORS.MAIN.GRADIENT)}
        currentCardIndex={currentCardIndex}
        onChangeSelectedCard={onChangeSelectedCard}
        showError={showError}
        showSuccess={showSuccess}
        showInfo={showInfo}
        applicationAddress={applicationAddress}
        options={
          isApplication ? undefined : (
            <ManageCard
              currentAccountId={currentAccountId}
              currentCardId={currentCardId}
              isVirtual={isVirtual}
              onGetPIN={onOpenGetPIN}
              showError={showError}
              showSuccess={showSuccess}
            />
          )
        }
        onFreeze={onOpenFreezeModal}
        onUnfreeze={onUnfreeze}
        onBlock={onBlock}
        onEnroll={onOpenEnroll}
        onUnenroll={onUnenroll}
        onActivate={onOpenActivate}
        onReplace={onOpenReplace}
      />
      <FreezeModal
        isOpen={modals.freeze}
        onClose={onCloseFreezeModal}
        onFreeze={freezeCard}
      />
      <ReplaceModal
        isOpen={modals.replace}
        onClose={onCloseReplace}
        onReplace={onReplace}
      />
      <DrawerGetPIN
        isOpen={drawers.pin}
        onClose={onCloseGetPIN}
        showError={showError}
        showSuccess={showSuccess}
      />
      <DrawerEnroll
        isOpen={drawers.enroll}
        onClose={onCloseEnroll}
        showError={showError}
        showSuccess={showSuccess}
      />
      <DrawerActivate
        isOpen={drawers.activate}
        onClose={onCloseActivate}
        showError={showError}
        showSuccess={showSuccess}
      />
    </>
  );
};

export default Card;
