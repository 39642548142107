import { general, generalFields } from "../../../../../common/lib/schemas/rules";

export const getSendInternationalPaymentScheme = (
  t: (descriptor: object, values?: object) => string,
) => ({
  beneficiary: [
    general(t)['required-field'],
    general(t)['min-length'](2),
    general(t)['max-length'](100),
    general(t)['numbers-and-letters-and-spaces']
  ],
  lastname: [
    general(t)['required-field'],
    general(t)['min-length'](2),
    general(t)['max-length'](100),
    general(t)['numbers-and-letters-and-spaces']
  ],
  iban: [
    general(t)['required-field'],
    ...generalFields(t)['iban']
  ],
  bic: [
    general(t)['required-field'],
    general(t)['numbers-and-letters-and-spaces'],
    general(t)['min-length'](6),
    general(t)['max-length'](11),
  ],
  amount: [
    general(t)['required-field'],
    ...generalFields(t)['amount']
  ],
  confirmAmount: [
    general(t)['required-field'],
  ],
  details: [
    general(t)['required-field'],
    ...generalFields(t)['describe']
  ],
  country: [
    general(t)['numbers-and-letters-and-spaces'],
    general(t)['required-field'],
  ],
  address1: [
    general(t)['required-field'],
    general(t)['numbers-and-letters-and-spaces'],
    general(t)['max-length'](30),
  ],
  address2: [
    general(t)['numbers-and-letters-and-spaces'],
    general(t)['max-length'](30),
  ],
  city: [
    general(t)['numbers-and-letters-and-spaces'],
    general(t)['required-field'],
    general(t)['max-length'](30),
  ],
  state: [
    general(t)['numbers-and-letters-and-spaces'],
    general(t)['max-length'](30),
  ],
  postCode: [
    general(t)['required-field'],
    ...generalFields(t)['postalCode']
  ],
});