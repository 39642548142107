import React from 'react'
import { Modal, Button } from 'antd';
import { useIntl } from 'react-intl';
import styles from './style.module.css'
import { ReactComponent as ExclamationIcon } from 'common/assets/svg/exclamation.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getAccounts, getSoftBlockModal } from 'river/store/selectors';
import { hideSoftBlockModal } from 'river/store/actions/cards';

export const SoftBlockModal = () => {
    const { formatMessage: t } = useIntl();
    const isSoftBlockModal = useSelector(getSoftBlockModal);
    const dispatch = useDispatch()
    console.log('isSoftBlockModal', isSoftBlockModal)
      const handleCancel = () => {

        dispatch(hideSoftBlockModal())
      };
      const handleOk = () => {
        // navigate()
        dispatch(hideSoftBlockModal())
      };
  return (
    <>
    <Modal 
    footer={null} 
    title={t({id: "river.sheets.accountSoftBlocked.title"})} 
    open={isSoftBlockModal} 
    onOk={handleOk} 
    onCancel={handleCancel}
    classNames={{
      header: styles.headerTitle,
    }}
    >
      <ExclamationIcon className={styles.icon}/>
        <p className={styles.text}>
            {t({id: "river.sheets.accountSoftBlocked.subtitle"})}
        </p>
        <Button href='mailto:cardservices@weststeincard.com' onClick={handleOk} type="primary" block>{t({id:"river.sheets.accountSoftBlocked.button"})}</Button>
       
      </Modal>
      </>
  )
}
