import React, { ChangeEvent, useEffect, useState } from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Flex, Form, FormInstance, Input, Typography } from 'antd';
import { useIntl } from 'react-intl';
import classes from '../../Settings.module.css';
import { BlackButton } from 'common/components/UI';
import email, { EmailChangeState } from '../Email';
import { general, generalFields } from '../../../../../lib/schemas/rules';

const { Text } = Typography;
const { TextArea } = Input;

type EmailRequestStepProps = {
  form: FormInstance,
  formData: Partial<{ email: string, reason?: string }>,
  emailChangeForm: EmailChangeState,
  setEmailChangeForm: (emailState: (EmailChangeState | ((prev: EmailChangeState) => EmailChangeState))) => unknown,
  onNextStep: React.MouseEventHandler<HTMLElement>;
};

const EmailRequestStep: React.FC<EmailRequestStepProps> = ({
                                                             form,
                                                             formData,
                                                             emailChangeForm,
                                                             setEmailChangeForm,
                                                             onNextStep,
                                                           }) => {
  const { formatMessage: t } = useIntl();

  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string[] }>({
    email: [],
  })

  const onFieldsChange = () => {
    setValidationErrors(prevState => ({
      ...prevState,
      email: form.getFieldError('email'),
    }))
  }

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setEmailChangeForm(prev => ({
          ...prev,
          haveErrors: false
        }));
      })
      .catch(() => {
        setEmailChangeForm(prev => ({
          ...prev,
          haveErrors: true
        }))
      });
  }, [form, emailChangeForm.email, emailChangeForm.reason, emailChangeForm.haveErrors]);

  const setEmail = (email: string) => {
    setEmailChangeForm((prevState) => ({
      ...prevState,
      email
    }))
  }

  const setReason = (reason: string) => {
    setEmailChangeForm((prevState) => ({
      ...prevState,
      reason
    }))
  }

  return (
    <div>
      <Flex
        className={classes['slide-wrapper']}
        justify="center"
        align="center"
        vertical
        gap={16}>
        <MailOutlined
          style={{
            fontSize: 36,
            padding: 18,
            boxSizing: 'content-box',
            background: 'rgba(0, 0, 0, 0.04)',
            borderRadius: 100,
          }}
        />
        <Form
          form={form}
          onFieldsChange={onFieldsChange}
          initialValues={formData}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            alignItems: 'center'
          }}
        >
          <Text className={classes.hint}>{t({ id: 'profile.settings.email.new' })}:</Text>
          <Form.Item help={
            <div id='error__email'>{validationErrors.email[0]}</div>
          } style={{ width: '100%' }} validateTrigger='onChange' name="email" rules={[
            general(t)['required-field'],
            ...generalFields(t).email,
          ]}>
            <Input
              placeholder={t({ id: 'inputs.email' })}
              value={emailChangeForm.email}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            />
          </Form.Item>
          <Flex vertical gap={16} style={{ width: '100%' }} align='center'>
            <Text className={classes.hint}>{t({ id: 'profile.settings.email.reason' })}:</Text>
            <Form.Item style={{ width: '100%' }} rules={[general(t)['required-field'], general(t)['numbers-and-letters-and-spaces']]} name='reason'>
              <TextArea
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }} 
                value={emailChangeForm.reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder={t({ id: 'inputs.reason' })}
              />
            </Form.Item>
          </Flex>
          <BlackButton disabled={!form.getFieldValue('email') || emailChangeForm.haveErrors} type="primary"
                       onClick={onNextStep}>
            {t({ id: 'screens.setting.setting.phone.buttons.confirm' })}
          </BlackButton>
        </Form>
      </Flex>
    </div>
  )
    ;
};

export default EmailRequestStep;
