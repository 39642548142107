import { Dispatch } from 'redux';
import API from '../../api';
import { batch } from 'react-redux';
import { Account } from 'river/types';
import { RiverThunkAction, RiverThunkDispatch } from 'river/types/thunk';
import { refreshRiver } from '.';
import { AxiosRequestConfig } from 'axios';
import { getCards } from "./cards";

export const getAccounts = () => {
  return async (dispatch: Dispatch): Promise<{ value: Account[] } | []> => {
    try {
      const {
        data: { response: accounts },
      } = await API.user.getAccounts();

      dispatch({
        type: 'River/SET_ACCOUNTS',
        payload: accounts,
      });
      return accounts;
    } catch (e) {
      return [];
    }
  };
};

export const refreshAccounts = () => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      const {
        data: { response: accounts },
      } = await API.user.getAccounts();
      // eslint-disable-next-line no-debugger
      debugger

      let mappedAccounts = [...accounts];
      if (mappedAccounts.length > 0) {
        mappedAccounts = await Promise.all(
          mappedAccounts.map(async account => {
            try {
              const cards = await dispatch(getCards(account.id));

              return { ...account, cards };
            } catch (e) {
              return { ...account, cards: [] };
            }
          })
        );
      }

      dispatch({
        type: 'River/REFRESH_ACCOUNTS',
        payload: mappedAccounts,
      });
      return mappedAccounts;
    } catch (e) {
      throw e;
    }
  };
};

export const getClient = ({ toState = true } = {}): RiverThunkAction => {
  return async (dispatch: Dispatch, getState) => {
    const userMeta = getState().user.meta;
    try {
      const {
        data: { response },
      } = await API.user.getClientData();
      const { is_soft_blocked } = response;
      if (is_soft_blocked) {
        if (is_soft_blocked !== '0') {
          dispatch({
            type: 'River/SHOW_SOFT_BLOCK_MODAL',
          });
        }

        dispatch({
          type: 'River/IS_SOFT_BLOCK',
          payload: is_soft_blocked == '1' ? true : false,
        });
      }
      if (toState) {
        batch(() => {
          dispatch({
            type: 'River/SET_META',
            payload: response,
          });
          dispatch({
            type: 'User/SET',
            payload: { meta: { ...userMeta, river: response.status } },
          });
        });
      }
      return response;
    } catch (e) {
      return {};
    }
  };
};

export const getApplications = (): RiverThunkAction => {
  return async (dispatch: Dispatch) => {
    try {
      const {
        data: { response: applications },
      } = await API.card.getApplications();

      const formattedApplications = applications
        .filter((application) => application.status !== 'closed')
        .map((application) => ({
          id: String(application.id),
          accountId: application.account_id,
          isApplication: true,
          type: 'plastic',
          status: 'application',
          numberMasked: application.card_number_masked,
        }));

      dispatch({
        type: 'River/SET_APPLICATIONS',
        payload: formattedApplications,
      });

      return formattedApplications;
    } catch (e) {
      return [];
    }
  };
};

export const setCurrentAccount = (id: string | number) => ({
  type: 'River/SET_CURRENT_ACCOUNT',
  payload: +id,
});

export const setAccountTitle = (id: number, title: string) => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      await API.user.updateAccountTitle(id, title);
      dispatch({
        type: 'River/SET_ACCOUNT_TITLE',
        payload: { id: String(id), title },
      });
    } catch (e) {
      throw e;
    }
  };
};

export const signVirtualCard = (
  accountId: number,
  cardId: string,
  body: { code?: string; password: string },
  options?: AxiosRequestConfig,
) => {
  return async (dispatch: RiverThunkDispatch) => {
    try {
      await API.card.signVCApplication(accountId, cardId, body, options);
      await dispatch(refreshRiver());
    } catch (error) {
      throw error;
    }
  };
};

export const createVirtualCardResend = async (accountId: number, cardId: string) => {
  try {
    await API.card.resendVCToken(accountId, cardId);
  } catch (error) {
    throw error;
  }
};
